import React, { useState } from 'react';

const WarehouseMetrics = () => {
  const defaultDashboard = 'https://share.geckoboard.com/dashboards/ZETSBTBG5WVB5EO5';
  const [selectedDashboard, setSelectedDashboard] = useState(defaultDashboard);
  const [menuOpen, setMenuOpen] = useState(false);

  const dashboards = [
    { name: 'Throughput', url: defaultDashboard },
    { name: 'Finance Board', url: 'https://share.geckoboard.com/dashboards/USHBWHTD344M6EGO' },
    { name: 'Ontime', url: 'https://share.geckoboard.com/dashboards/JZVKEYJIN3XKYJVV' },
    { name: 'Output KPI', url: 'https://share.geckoboard.com/dashboards/USJTDUJDEUG7K3RY' },
    { name: 'Inventory Accuaracy', url: 'https://share.geckoboard.com/dashboards/QSDU76HS5XP77TR6' },
  ];

  return (
    <div className="relative flex h-full">
      {/* Main Content Area */}
      <div
        className={`flex-1 transition-all duration-300 ${
          menuOpen ? 'ml-[250px]' : 'ml-0'
        }`}
      >
        {/* Hamburger Menu */}
        <div className="absolute top-4 left-4 z-20">
          <button
            onClick={() => setMenuOpen((prev) => !prev)}
            className="p-2 bg-blue-600 text-white rounded-lg shadow-md"
          >
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white"></span>
          </button>
        </div>

        {/* Iframe */}
        <iframe
          src={selectedDashboard}
          title="Warehouse Metrics"
          className="w-full h-[calc(100vh-64px)] border-0"
        />
      </div>

      {/* Fly-out Menu */}
      <div
        className={`fixed top-0 left-0 h-full bg-slate-800 text-white shadow-lg transform ${
          menuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300`}
        style={{
          width: '250px',
          backgroundColor: 'rgba(31, 41, 55, 0.95)', // Solid dark background with slight transparency
          zIndex: 50, // Ensure it is above other elements
        }}
      >
        <div className="p-4">
          <h2 className="text-lg font-semibold mb-4">Dashboards</h2>
          <ul className="space-y-4">
            {dashboards.map((dashboard) => (
              <li key={dashboard.url}>
                <button
                  onClick={() => {
                    setSelectedDashboard(dashboard.url);
                    setMenuOpen(false); // Close menu after selection
                  }}
                  className={`w-full text-left px-6 py-3 rounded-lg ${
                    selectedDashboard === dashboard.url
                      ? 'bg-blue-600 border-l-4 border-blue-500 text-white'
                      : 'bg-slate-700 text-gray-300 hover:bg-slate-600 hover:text-white'
                  }`}
                >
                  {dashboard.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WarehouseMetrics;
